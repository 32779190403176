import { registerLocaleData } from '@angular/common';
import { LOCALE_LOAD_MAPPING } from 'common';
/**
 * https://stackoverflow.com/questions/61035621/how-do-i-dynamically-import-locales-in-angular-9
 * https://medium.com/angular-in-depth/dynamic-import-of-locales-in-angular-b994d3c07197
 * https://stackoverflow.com/questions/69970566/module-not-found-error-package-path-locales-is-not-exported-from-package-aft
 */
export class LocaleLoader {
  public static load(locale: string): Promise<any> {
    // register locale for pipes

    let mappedLocal = locale;

    if (locale in LOCALE_LOAD_MAPPING) {
      mappedLocal = LOCALE_LOAD_MAPPING[locale];
    }

    return import(
      /* webpackInclude: /(fr|ar|bg|hr|cs|da|en|en-CA|en-SG|fi|de|de-CH|el|he|hu|nl|it|es|ja|ko|nb|pl|pt-PT|ro|ru|sr|sk|sl|sv|es-MX|es-AR|th|tk|uk|vi|zh|zh-Hans-HK|zh-Hans-SG)\.mjs/ */
      /* webpackMode: "lazy-once" */
      /* webpackChunkName: "i18n-base" */
      `../../../../node_modules/@angular/common/locales/${mappedLocal}.mjs`
    )
      .then((loadedLocale) => {
        if (mappedLocal !== locale) registerLocaleData(loadedLocale.default, mappedLocal);
        registerLocaleData(loadedLocale.default, locale);
        console.log('Registered locale ', loadedLocale.default[0], locale, mappedLocal);
        return loadedLocale;
      })
      .catch((err) => {
        console.error('Translation file ' + mappedLocal + ' not found for Angular pipes.');
        return err;
      });
  }
}
