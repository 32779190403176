import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EnvService, Ic2Module, MsPipe } from '@ic2/ic2-lib';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';
import { OAuthModule } from 'angular-oauth2-oidc';
import {
  ClientEnv,
  Config,
  HijiCommonModule,
  HijiHttpTranslateLoader,
  InterpolatedMissingTranslationHandler,
  LocalizedDatePipe,
  NgbDatepickerI18nCustom,
  loadClientStyle,
} from 'common';
import { environment } from 'environments/environment';
import { CookieModule } from 'ngx-cookie';
import { MomentModule } from 'ngx-moment';

import { PreloadAllModules } from '@angular/router';
import { NgbDateParserFormatter, NgbDatepickerI18n, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { StaticPageComponent } from 'projects/frontoffice/src/app/pages/static-page/static-page.component';
import { firstValueFrom } from 'rxjs';
import { trustedResourceUrl, unwrapResourceUrl } from 'safevalues';
import { AcademyComponent } from './academy/academy.component';
import { SupportComponent } from './admins/support/support.component';
import { AppComponent } from './app.component';
import { BackLayoutComponent } from './back-layout/back-layout.component';
import { HijiDateParserFormatter } from './hiji-date-parser-formatter';
import { LemonComponent } from './lemon/lemon.component';
import { routes } from './routes';
import { TranslateTextsComponent } from './translate-texts/translate-texts.component';
import { CommonWavyModule } from './wavy-bg/common-wavy.module';

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new HijiHttpTranslateLoader(http);
}
export class DynamicLocaleId extends String {
  constructor(protected service: TranslateService) {
    super('');
  }

  toString() {
    return this.service.currentLang;
  }
}

@NgModule({
  declarations: [AppComponent, BackLayoutComponent, SupportComponent, TranslateTextsComponent, StaticPageComponent, AcademyComponent, LemonComponent],
  imports: [
    CommonModule,
    //NgChartsModule,
    //ReactiveFormsModule,
    //FormsModule,
    NgbModule,
    //DashboardModule,
    //DragDropModule,
    Ic2Module,
    HijiCommonModule,
    CookieModule.withOptions(),
    OAuthModule.forRoot(),
    MonacoEditorModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: InterpolatedMissingTranslationHandler },
      defaultLanguage: 'en',
      extend: true,
    }),
    ServiceWorkerModule.register(unwrapResourceUrl(trustedResourceUrl`ngsw-worker.js`) as string, {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', preloadingStrategy: PreloadAllModules }),
    CommonWavyModule,
    MomentModule.forRoot({
      relativeTimeThresholdOptions: {
        m: 59,
      },
    }),
  ],
  providers: [
    //provideExperimentalZonelessChangeDetection(),
    //provideZoneChangeDetection({ eventCoalescing: true }),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: LOCALE_ID, useClass: DynamicLocaleId, deps: [TranslateService] },
    { provide: NgbDatepickerI18n, useClass: NgbDatepickerI18nCustom },
    MsPipe,
    LocalizedDatePipe,
    {
      provide: APP_INITIALIZER,
      useFactory: (envService: EnvService<Config>, clientEnv: ClientEnv) => {
        console.log('loading env for', window.location.hostname);

        if (['bo.hiji.local', 'localhost', '192.168.1.1', 'maison.deepneel.fr'].includes(window.location.hostname)) {
          envService.confUrl = window.location.protocol + '//' + window.location.hostname + ':8080/front.config.json';
        } else {
          const domainName = window.location.hostname;
          envService.confUrl = window.location.protocol + '//api.' + domainName.substring(domainName.indexOf('.') + 1) + '/front.config.json';
        }

        return () =>
          new Promise<void>((resolve, reject) => {
            let timeout = setTimeout(() => {
              reject(new Error('Time'));
            }, 3000);

            firstValueFrom(envService.load(new Config()))
              .then((config) => {
                loadClientStyle(config, clientEnv);
                clearTimeout(timeout);
                resolve();
              })
              .catch((e) => {
                console.error(e);
                clearTimeout(timeout);
                reject(new Error('Down'));
              });
          });
      },
      deps: [EnvService, ClientEnv],
      multi: true,
    },
    { provide: NgbDateParserFormatter, useClass: HijiDateParserFormatter },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
